import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 px-5 md:px-10 py-5" }
const _hoisted_2 = { class: "relative flex flex-col mt-6 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl w-96" }
const _hoisted_3 = { class: "p-6 pb-2 space-y-3" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "block mb-2 font-sans text-xl antialiased font-bold leading-snug tracking-normal text-blue-gray-900" }
const _hoisted_6 = { class: "block font-sans text-base font-medium antialiased font-light leading-relaxed text-inherit" }
const _hoisted_7 = { class: "p-6 pt-0" }
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
      return (_openBlock(), _createElementBlock("div", {
        key: post.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.imageSrc(post.description),
              alt: "Image",
              class: "rounded-lg"
            }, null, 8, _hoisted_4),
            _createElementVNode("h5", _hoisted_5, _toDisplayString(post.title), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.limitedText(post.description)), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("a", {
              href: post.link,
              target: "_blank",
              class: "align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-yellow-500 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none",
              type: "button"
            }, " Read more ", 8, _hoisted_8)
          ])
        ])
      ]))
    }), 128))
  ]))
}