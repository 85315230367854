
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'Blog',
  setup() {
    interface Post {
      title: string;
      link: string;
      description: string;
      image: string;
      id: string;
    }

    const posts = ref<Post[]>([]);

    onMounted(async () => {
      const response = await fetch(
        'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@carishworld'
      ).then((res) => res.json()).catch(err => console.log(err))

      posts.value = response.items.map((item: any) => ({
        title: item.title,
        link: item.link,
        description: item.description,
        ...item
      }));
    });

    const limitedText = (description: string) => {
      const strippedText = description.replace(/<[^>]+>/g, '');
      return strippedText.slice(0, 30) + '...';
    }

    const imageSrc = (description: string) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(description, 'text/html');
      const img = doc.querySelector('img');
      return img?.getAttribute('src') || '';
    }

    return { posts, limitedText, imageSrc };
  },
});
