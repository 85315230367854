import { createRouter, createWebHistory} from "vue-router";
import HomeView from "../views/HomeView.vue";
import BlogViewVue from "@/views/BlogView.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/presale",
      name: "presale",
      component: () => import("../views/PreSale.vue"),
    },
    {
      path: "/blog",
      name: "blog",
      component: BlogViewVue,
    },
  ],
});

export default router;
