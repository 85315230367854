export default {
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  presale: {
    97: '0x76aDd2441b94526c22263fa457e356358415B5d6',
    56: '0x52166fe6468EbA0F1afD8EBA80B35F23D0c9030E',
  },
};
