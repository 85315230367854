import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "token-sale-inner" }
const _hoisted_2 = { class: "token-count" }
const _hoisted_3 = { class: "single-count" }
const _hoisted_4 = { id: "days" }
const _hoisted_5 = { class: "single-count" }
const _hoisted_6 = { id: "hours" }
const _hoisted_7 = { class: "single-count" }
const _hoisted_8 = { id: "minutes" }
const _hoisted_9 = { class: "single-count" }
const _hoisted_10 = { id: "seconds" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.daysSpan), 1),
        _createTextVNode(" DAYS ")
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.hoursSpan), 1),
        _createTextVNode(" HOURS ")
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.minutesSpan), 1),
        _createTextVNode(" MIN ")
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.secondsSpan), 1),
        _createTextVNode(" SEC ")
      ])
    ])
  ]))
}