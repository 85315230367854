
import { Options } from "vue-class-component";
import Web3ModalVue from "@/components/Web3ModalVue.vue";
import WalletConnectProvider from "@walletconnect/web3-provider";
import ConnectToTrustWallect from "@/providers/connectors/trust";
import injection from "@/providers/connectors/injected";
import { BASE_BSC_SCAN_URL } from "@/config/index";
import { getPresaleAddress } from "@/utils/addressHelpers";
import Web3Mixins from './helpers/mixins/web3Mixin';
import Dialog from "primevue/dialog";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Header from "@/components/Header.vue";
import { cache } from '@/utils/cache';
import { isEmpty } from "lodash";

declare global {
  interface Window {
    BinanceChain: any;
    particlesJS: any;
  }
}

@Options({
  components: {
    Toast,
    Dialog,
    ConfirmDialog,
    Button,
    Web3ModalVue,
    Header,
  },
  watch: {
    "$route.query": {
      async handler(query) {
        const referrer = query.ref ?? query.referrer;
        if (referrer && !isEmpty(referrer)) {
          this.referrerAddress = referrer;
        }
      },
      immediate: true,
    },
  },
})
export default class App extends Web3Mixins {
  BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URL;
  cache: any = cache;

  contactLinks = {
    telegram: `https://t.me/CARISHOFFICIAL`,
    twitter: `https://twitter.com/CARISHglobal`,
    facebook: `https://www.facebook.com/profile.php?id=61552951361669&mibextid=ZbWKwL`,
    tiktok: `https://tiktok.com/@carishcaris`,
  };

  providerOptions = {
    "custom-metamask": {
      display: {
        logo: "/images/logos/metamask.svg",
        name: "MetaMask",
        description: "Connect to your MetaMask",
      },
      package: true,
      connector: async () => injection(),
    },
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          56: "https://bsc-dataseed.binance.org/",
        },
        network: "binance",
        chainId: 56,
      },
    },
    "custom-trustwallet": {
      display: {
        logo: "/images/logos/trust.svg",
        name: "TrustWallet",
        description: "Connect to your TrustWallet",
      },
      package: true,
      connector: async (_, __, opt) =>
        ConnectToTrustWallect(
          WalletConnectProvider,
          {
            rpc: {
              56: "https://bsc-dataseed.binance.org/",
            },
            network: "binance",
            chainId: 56,
          },
          opt
        ),
    },
    "custom-binancechainwallet": {
      display: {
        logo: "/images/logos/binance.png",
        name: "Binance Chain Wallet",
        description: "Connect to your Binance Chain Wallet",
      },
      package: true,
      connector: async () => {
        let provider = null;
        if (typeof window.BinanceChain !== "undefined") {
          provider = window.BinanceChain;
          try {
            await provider.request({ method: "eth_requestAccounts" });
          } catch (error) {
            throw new Error("User Rejected");
          }
        } else {
          throw new Error("No Binance Chain Wallet found");
        }
        return provider;
      },
    },
  };

  declare $refs: {
    web3modal: any;
    toast: any;
    txToast: any;
  };

  copy(content, title) {
    if (!!content) {
      const el = document.createElement('textarea');
      el.value = content;
      el.setAttribute('readonly', '');
      // el.style = { display: 'none' };
      document.body.appendChild(el);
      el.select();
      el.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand('copy');
      document.body.removeChild(el);

      this.$store.commit("useToast", {
        severity: "info",
        summary: "Copied",
        detail: `Successfully copied (${title})`,
      });

    } else this.$store.commit("useToast", {
      severity: "error",
      summary: "Failed to Copy",
      detail: `No data found!`,
    });
  }

  get contractAddress() {
    return getPresaleAddress();
  }

  mounted() {
    this.$store.commit("setToast", this.$refs.toast);
    this.$store.commit("setTxToast", this.$refs.txToast);

    this.$nextTick(async () => {
      const web3modal = this.$refs.web3modal;
      this.$store.commit("setWeb3Modal", web3modal);
      if (web3modal.cachedProvider) {
        this.$store.dispatch("connect");
      }
    });
  }
}
