import multicall from "@/utils/multicall";
import presaleABI from "@/config/abis/presale.json";
import BigNumber from "bignumber.js";
import { getPresaleAddress } from "@/utils/addressHelpers";
import { simpleRpcProvider } from "@/utils/web3";

const userStakeCalls = (account: string) => [
  {
    address: getPresaleAddress(),
    name: "vestingSchedules",
    params: [account],
  },
];

export const fetchUserDetails = async (account) => {
  const result = await multicall(presaleABI, userStakeCalls(account));
  const info = result[0]

  return {
    amountClaim: new BigNumber(info.amountClaim.toString()),
  };
};

export const fetchBalance = async (account: string) => {
  const provider = simpleRpcProvider;
  const balance = await provider.getBalance(account);

  return new BigNumber(balance.toString());
};

const generalUserData = async (account: string) => {
  const [userBalance, userPresaleDetails] = await Promise.all([
    fetchBalance(account),
    fetchUserDetails(account),
  ]);

  const userData = {
    balance: userBalance,
    ...userPresaleDetails,
  };

  return userData;
};

export const getUserPresaleData = async (account: string) => {
  const stakingData = await generalUserData(account);

  return { ...stakingData };
};
