import presaleConfig from '@/config/constants/presale';
import { SerializedPresale } from '@/config/constants/types';
import { getUserPresaleData } from './fetchPresaleData';

export interface PresaleState {
  data: SerializedPresale,
  userDataLoaded: boolean
}

const presaleStore = {
  state: <PresaleState>{
    data: {...presaleConfig},
    userDataLoaded: false
  },
  mutations: {
    setPresaleData(state: PresaleState, { data }: { data: SerializedPresale }) {
      state.data = data;
    },
    updatePresaleUserData(state: PresaleState, payload: any) {
      const { field, value } = payload;

      state.data = { ...state.data, userData: { ...state.data.userData, [field]: value }};
    },
    setPresaleUserData(state: PresaleState, payload: any) {
      const userData = payload;

      state.data = {...state.data, userData: userData }
      state.userDataLoaded = true;
    }
  },
  actions: {
    async fetchPresaleUserDataAsync({ state, commit, rootState, dispatch }, account: string) {
      try {
        const userData = await getUserPresaleData(account);

        commit('setPresaleUserData', userData);
      } catch (error) {
        console.error('[Pools Action] Error fetching pool user data', error)
      }
    }
  }
}

export default presaleStore;