
import { BASE_BSC_SCAN_URL } from "@/config";
import CommonMixin from "@/helpers/mixins/CommonMixin";
import { Options } from "vue-class-component";
import Clock from '@/components/Clock.vue';
import CountDownClock from '@/components/CountDownClock.vue';
import CountDown from '@/components/CountDown.vue';

declare global {
  interface Window {
    Chart: any;
  }
}

@Options({
  components: {
    Clock,
    CountDownClock,
    CountDown,
  },
})
export default class Home extends CommonMixin {
  BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URL;

  mounted() {
    setTimeout(() => {
      const data = {
        labels: [
          "Private sale",
          "Presale",
          "DEX Liquidity",
          "CEX listing",
          "Charity",
          "Marketing",
          "Dev Team",
          "Community Rewards",
        ],
        datasets: [
          {
            data: [5, 15, 10, 25, 15, 10, 10, 10],
            backgroundColor: [
              "#FFFF33",
              "#FFFF66",
              "#FFCC00",
              "#FF9900",
              "#FFCC99",
              "#FF6600",
              "#FFCC33",
              "#FFCC66",
            ],
          },
        ],
      };

      const configPie = {
        type: "pie",
        data: data,
        options: {
          plugins: {
            legend: {
              display: true,
              position: "right",
            },
          },
        },
      };

      new window.Chart(document.getElementById("canvas"), configPie);
    }, 3000)
  }
}
