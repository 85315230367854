import BigNumber from 'bignumber.js'

export enum FetchStatus {
  Idle = 'IDLE',
  Fetching = 'FETCHING',
  Fetched = 'FETCHED',
  Failed = 'FAILED',
}

export enum GAS_PRICE {
  default = '5',
  fast = '6',
  instant = '7',
  testnet = '10',
}

export interface Address {
  97?: string
  56: string
}

export interface SerializedToken {
  chainId: number
  address: string
  decimals: number
  symbol?: string
  name?: string
  projectLink?: string
}

export interface SerializedPresale {
  minAmount: BigNumber,
  maxAmount: BigNumber;
  userData?: {
    amountClaim: BigNumber;
    balance: BigNumber;
  }
}

export interface SerializedPresaleState {
  data: SerializedPresale[]
  userDataLoaded: boolean
}

export enum ChainId {
  MAINNET = 56,
  TESTNET = 97,
}

export type SerializedBigNumber = string