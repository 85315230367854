
import Web3Mixins from "../helpers/mixins/web3Mixin";
export default class ConnectWalletButton extends Web3Mixins {
  window = {
    width: 0,
    height: 0
  }

  isNavShowing = false;

  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize();
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    this.window.width = window.innerWidth;
    this.window.height = window.innerHeight;
  }

  mounted() { }
}
